'use client';

import { useEffect } from 'react';

import { useRouter } from 'next/navigation';
import { usePathname, useSearchParams } from 'next/navigation';

import { setLocalStorageCouponCode } from '../actions/referralsAndCoupons';

const makeUTMSearchParams = (searchParams: URLSearchParams, influencer: string) => {
  const params = new URLSearchParams(searchParams);

  params.set('utm_medium', 'influencer');
  params.set('utm_campaign', 'q3_giveaway');
  params.set('utm_source', influencer);

  return params;
};

const CouponDialog_CouponToUTM = ({ code }: { code: string }) => {
  const searchParams = useSearchParams();
  const pathName = usePathname();
  const { replace } = useRouter();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!pathName) {
      return;
    }

    if (code) {
      setLocalStorageCouponCode({ code });
      replace(
        `${pathName}?${searchParams ? makeUTMSearchParams(searchParams, pathName.replace(/\/$/, '')).toString() : ''}`
      );
    } else if (searchParams?.has('coupon')) {
      setLocalStorageCouponCode({ code: searchParams.get('coupon') || '' });
    }
  }, [code]);

  return null;
};

export default CouponDialog_CouponToUTM;
