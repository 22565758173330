'use client';

import ButtonLink from '@/components/ButtonLink';
import useUser from '@/features/user/hooks/useUser';
import Button from '@wander/ui/src/components/Button/Button';
import { useDialog } from '@wander/ui/src/components/Dialog/DialogProvider';
import { useNotify } from '@wander/ui/src/providers/NotificationsProvider';

const CouponDialog_Button = ({
  children,
  code,
  redirectTo,
  className,
}: {
  children: React.ReactNode;
  code: string;
  redirectTo: string;
  className?: string;
}) => {
  const { user, toggleLoginDialog } = useUser();
  const [_, close] = useDialog();
  const notify = useNotify();

  const handleClick = () => {
    if (!user) {
      toggleLoginDialog();
    } else {
      if (code) {
        notify.success({
          title: `Coupon code applied (${code})`,
        });
      }
      close();
    }
  };

  return redirectTo ? (
    <ButtonLink href={redirectTo} className={className}>
      {children}
    </ButtonLink>
  ) : (
    <Button onClick={handleClick} className={className}>
      {children}
    </Button>
  );
};

export default CouponDialog_Button;
