import PropertyFallback from '../Property/PropertyFallback';

const ListProperties_Fallback = () => (
  <>
    {[...Array(11).keys()].map((_, i) => (
      <PropertyFallback key={i} className='animate-pulse px-2 py-3' />
    ))}

    <div className='col-[1] row-[6] grid h-full w-full animate-pulse grid-rows-2 place-items-start gap-4 px-2 py-3 sm:row-[2]'>
      <div className='h-full w-full rounded bg-w-8002' />
      <div className='h-full w-full rounded bg-w-8002' />
    </div>
  </>
);

export default ListProperties_Fallback;
