'use client';

import { sendGTMEvent } from '@next/third-parties/google';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

const TrackSearch = () => {
  const searchParams = useSearchParams();

  useEffect(() => {
    if (searchParams.has('category')) {
      sendGTMEvent({
        event: 'products_list_viewed',
        data: {
          product_category: searchParams.get('category'),
        },
      });
    }

    if (searchParams.has('search')) {
      sendGTMEvent({
        event: 'products_searched',
        data: {
          search_query: searchParams.get('search'),
        },
      });
    }
  }, [searchParams]);

  return null;
};

export default TrackSearch;
