'use client';

import getLandingPageFilteredProperties from '../../actions/getLandingPageFilteredProperties';
import { PER_PAGE } from '../../actions/getProperties/const';
import Property from '../Property';

import LoadMore from '../Search/LoadMore';

import useInMemoryState from '@wander/ui/src/hooks/useInMemoryState';
import { useCallback, useTransition } from 'react';
import type { PropertyLandingPage } from '../../actions/toLandingProperty';

const computeTotalGuests = (...compute: Array<string | number | null>) =>
  compute.reduce((acc, curr) => +(acc || 0) + +(curr || 0)) as number;

const fetchPage = async (page) => {
  const searchParams = new URLSearchParams(window.location.search);

  const guests = computeTotalGuests(
    searchParams.get('adults'),
    searchParams.get('children'),
    searchParams.get('infants')
  );

  return getLandingPageFilteredProperties({
    page,
    categorySlug: searchParams.get('category') || undefined,
    checkIn: searchParams.get('checkIn') || undefined,
    checkOut: searchParams.get('checkOut') || undefined,
    minOccupancy: guests || undefined,
    states: searchParams.get('states')?.length ? searchParams.get('states')?.split(',') : undefined,
  }).then((res) => res.data);
};

const useInfinitePropertyList = (searchKey, { properties }) => {
  const [data, setData] = useInMemoryState('properties/' + searchKey, { pages: [properties] });

  const [isFetching, startTransition] = useTransition();

  const fetchNextPage = useCallback(async () => {
    startTransition(async () => {
      const nextProperties = await fetchPage(data.pages.length + 1);

      // https://youtu.be/AJOGzVygGcY?si=rU5ViCS_xbYEjOcs&t=231
      startTransition(() => {
        setData((prevData) => ({
          pages: [...(prevData?.pages || []), nextProperties],
        }));
      });
    });
  }, [data.pages.length, setData]);

  return { data, fetchNextPage, isFetching };
};

const ClassicResults = ({
  searchKey,
  properties,
  perPage = PER_PAGE,
}: {
  searchKey: string;
  properties: PropertyLandingPage[];
  perPage?: number;
}) => {
  const { data, fetchNextPage, isFetching } = useInfinitePropertyList(searchKey, { properties });

  return (
    <>
      {data.pages.map((page, pageI) =>
        page.map((property, i) => <Property key={property.id} className='p-2' property={property} lazyLoad={i > 3} />)
      )}
      {(data.pages.at(-1)?.length || 0) == perPage ? (
        <LoadMore fetchNextPage={fetchNextPage} isPending={isFetching} />
      ) : null}
    </>
  );
};

export default ClassicResults;
