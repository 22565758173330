'use client';

import { Suspense, lazy, useEffect } from 'react';

import Icon from '@wander/ui/src/components/Icon';
import Section from '@wander/ui/src/components/Section';
import useInMemoryState from '@wander/ui/src/hooks/useInMemoryState';
import { useSearchParams } from 'next/navigation';

type Display = 'list' | 'map';

type Props = {
  children: React.ReactNode;
};

const PropertiesSection_Map = lazy(() => import('./PropertiesSection_Map'));

const PropertiesSection_ListOrMap = ({ children }: Props) => {
  const [display, setDisplay] = useInMemoryState<Display | undefined>('list', undefined);
  const searchParam = useSearchParams();

  // biome-ignore lint/correctness/useExhaustiveDependencies: refresh on searchParam change
  useEffect(() => {
    if (display == 'map') {
      window.scrollTo({ top: 400 });
    }
  }, [display, searchParam]);

  useEffect(() => {
    if (display == 'list') {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setDisplay('list');
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [display, setDisplay]);

  return (
    <Section
      id='properties-list-or-map'
      data-display={display}
      className={
        'relative mb-8 grid max-w-screen-xl items-start overscroll-none py-0 pt-0 pb-12 ' +
        (display == 'map'
          ? 'isolation-auto'
          : 'after:pointer-events-none after:left:0 after:absolute after:bottom-0 after:z-50 after:block after:h-32 after:w-full after:bg-gradient-to-b after:from-transparent after:via-1-black after:to-1-black')
      }
    >
      {display != 'map' && children}
      {display !== undefined ? (
        <div
          className={
            'fixed left-0 top-0 z-30 h-screen w-screen bg-1-black animate-fade-in ' + (display != 'map' && 'hidden')
          }
        >
          <Suspense fallback={null}>
            <PropertiesSection_Map />
          </Suspense>
        </div>
      ) : null}

      <button
        onClick={() =>
          setDisplay((display) => {
            if (display != 'map') {
              setTimeout(() => window.scrollTo({ top: 400, behavior: 'smooth' }), 50);
              return 'map';
            }
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return 'list';
          })
        }
        className='btn btn-sm sticky bottom-24 left-1/2 z-[51] w-fit -translate-x-1/2 px-4 text-1-black shadow-[0px_6px_12px_0px] shadow-1-black/30 transition-opacity group-[&[data-fixed="true"]]/body:opacity-100 group-has-[#properties-list-or-map[data-display="map"]]/body:fixed group-has-[#properties-list-or-map[data-display="map"]]/body:!opacity-100 max-lg:opacity-0 lg:bottom-8'
      >
        <Icon name={display != 'map' ? 'map' : 'list'} className='top-0 h-6 w-6' />
        {display != 'map' ? 'Map' : 'List'}
      </button>
    </Section>
  );
};

export default PropertiesSection_ListOrMap;
