'use client';

import useWindowSize, { useMinSize } from '../hooks/useWindowSize';

type Size = {
  width: number;
  height: number;
};

type ResponsiveComponent = React.ReactNode | ((size: Size) => React.ReactNode);

const renderComponent = (
  component: ResponsiveComponent,
  size: {
    width: number;
    height: number;
  }
) => {
  if (typeof component === 'function') {
    return component(size);
  }

  return component;
};

type Props = {
  base?: ResponsiveComponent;
  sm?: ResponsiveComponent;
  md?: ResponsiveComponent;
  lg?: ResponsiveComponent;
  xl?: ResponsiveComponent;
  xxl?: ResponsiveComponent;
  fallback?: ResponsiveComponent;
  defaultSize?: 'base' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
};

const Responsive = (props: Props) => {
  const { base, sm, md, lg, xl, xxl, fallback = null, defaultSize = undefined } = props;
  const size = useWindowSize();
  const isMin = useMinSize();

  if (!size || !isMin) {
    if (defaultSize && props[defaultSize]) {
      return renderComponent(props[defaultSize], { width: 0, height: 0 });
    }

    return renderComponent(fallback, { width: 0, height: 0 });
  }

  if (isMin.xxl() && xxl !== undefined) {
    return renderComponent(xxl, size);
  }

  if (isMin.xl() && xl !== undefined) {
    return renderComponent(xl, size);
  }

  if (isMin.lg() && lg !== undefined) {
    return renderComponent(lg, size);
  }

  if (isMin.md() && md !== undefined) {
    return renderComponent(md, size);
  }

  if (isMin.sm() && sm !== undefined) {
    return renderComponent(sm, size);
  }

  if (base !== undefined) {
    return renderComponent(base, size);
  }

  throw new Error(`No matching breakpoint for ${size.width}`);
};

export default Responsive;
