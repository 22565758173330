import clx from '../utils/clx';

type Props = {
  className?: string;
  as?: React.ElementType | 'p' | 'div' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const Title = ({ as: Component = 'p', children, className }: React.PropsWithChildren<Props>) => (
  <Component
    className={clx('font-headline text-4.5xl text-balance mx-auto !block text-center font-bold xl:text-5xl', className)}
  >
    {children as JSX.Element}
  </Component>
);

type HeadLineProps = Props & { headline?: string; headlineClassName?: string };

export const HeadLine = ({
  children,
  headline,
  className,
  headlineClassName,
}: React.PropsWithChildren<HeadLineProps>) => (
  <>
    <h2
      className={'text-eyebrow-xs text-balance ' + clx('text-w-4004 mx-auto !block text-center uppercase', className)}
    >
      {children as JSX.Element}
    </h2>
    {headline && <Title className={clx('mt-2', headlineClassName)}>{headline}</Title>}
  </>
);

type SectionProps = HeadLineProps & {
  title?: string;
  titleClassName?: string;
};

export const Section = ({
  children,
  className,
  title,
  headline,
  ...props
}: React.HTMLProps<HTMLDivElement> & SectionProps) => (
  <section {...props} className={clx('relative isolate mx-auto min-h-fit w-full py-12', className)}>
    {title && (
      <div className='absolute top-[100px] z-50 w-full'>
        <HeadLine headline={title}>{headline}</HeadLine>
      </div>
    )}
    {children}
  </section>
);

export default Section;
