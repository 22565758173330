'use client';

import { useCallback, useSyncExternalStore } from 'react';

const map = new Map();
const listeners = new Map<string, Array<() => void>>();

const emitChange = (key: string) => {
  for (const listener of listeners.get(key) || []) {
    listener();
  }
};

const store = {
  set(key: string, value: unknown) {
    map.set(key, value);
    emitChange(key);
  },
  subscribe: (key: string) => (listener: () => void) => {
    listeners.set(key, [...(listeners.get(key) || []), listener]);
    return () => {
      listeners.set(key, listeners.get(key)?.filter((l) => l !== listener) || []);
    };
  },
  getSnapshot: (key: string, initialState?: unknown) => () => map.get(key) ?? initialState,
};

const getValue = (key: string, initialState?: unknown) => store.getSnapshot(key, initialState)();

const useInMemoryState = <T>(key: string, initialState: T) => {
  const display = useSyncExternalStore<T | undefined>(
    store.subscribe(key),
    store.getSnapshot(key, initialState),
    () => initialState
  );
  const setDisplay = useCallback(
    (callback: T | ((value: T) => T)) => {
      const value = getValue(key, initialState);
      const nextValue = callback instanceof Function ? callback(value) : callback;
      store.set(key, nextValue);
      return nextValue;
    },
    [key]
  );

  return [display, setDisplay] as const;
};

export default useInMemoryState;
