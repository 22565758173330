const sessionCode = 'wanderSession::sessionCode';

export const getLocalStorageCouponCode = () => {
  return localStorage.getItem(sessionCode);
};

export const setLocalStorageCouponCode = ({ code }: { code: string }) => {
  localStorage.setItem(sessionCode, code);
};

export const removeLocalStorageCouponCode = () => {
  localStorage.removeItem(sessionCode);
};
