const ErrorMessage = () => (
  <>
    <div className='relative col-span-full mb-4 mt-2 w-full text-center'>
      <h1 className='mb-2 text-2xl'>We couldn&apos;t find anything</h1>
      <p className='mb-1 text-white/80'>
        Sorry, there is nothing available that matches your filters, but checkout some other options below
      </p>
    </div>
  </>
);

export default ErrorMessage;
