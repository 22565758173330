import clx from '@wander/ui/src/utils/clx';

const PropertyFallback = ({
  className,
  style,
  innerRef,
}: Omit<React.HTMLProps<HTMLDivElement>, 'ref'> & {
  innerRef?: React.Ref<HTMLDivElement>;
}) => (
  <div
    ref={innerRef}
    className={clx(
      'group relative grid aspect-square h-full w-full min-w-[335px] grid-rows-[1fr_auto] gap-3 sm:aspect-auto sm:grid-rows-[294px_auto]',
      className
    )}
    style={style}
  >
    <div className='relative h-full w-full rounded-lg bg-w-8002' />

    <div className='mt-1 flex h-full flex-col gap-1'>
      <div className='h-[10px] w-44 rounded bg-w-8002' />
      <div className='mt-1 h-4 w-44 rounded bg-w-8002' />
      <div className='mt-1 h-4 w-11/12 rounded bg-w-8002' />
    </div>
  </div>
);

export default PropertyFallback;
