'use client';

import client from '@/services/fetcher/client';
import clx from '@wander/ui/src/utils/clx';
import type { SearchChunk, SearchOutput } from '@wandercom/search-defs';
import { format, parseISO } from 'date-fns';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Property from '../Property';
import ErrorMessage from './ErrorMessage';
import ListProperties_Fallback from './ListProperties_Fallback';

type AiSearchResult = Array<
  SearchOutput['sections'][number]['units'][number] & {
    section: SearchOutput['sections'][number]['type'];
    sectionDates?: Extract<SearchOutput['sections'][number], { type: 'AVAILABLE' }>['date'];
  }
>;

const sectionTypeToLabel: Record<Extract<SearchChunk, { type: 'SECTION' }>['section']['type'], string> = {
  AVAILABLE: 'Available',
  EXACT_MATCH: 'Exact match',
  OUT_OF_PRICE_RANGE: 'Out of price range',
  SIMILAR_MATCH: 'Similar match',
  UNAVAILABLE: 'Unavailable',
};

const AiResults = ({ search }: { search: string }) => {
  const [results, setResults] = useState<AiSearchResult>();

  const isLoading = results === undefined;
  const isEmptyState = useMemo(() => {
    if (results === undefined) {
      return false;
    }
    return Object.values(results).flat().length === 0;
  }, [results]);

  useEffect(() => {
    const abortController = new AbortController();

    setResults(undefined);

    client
      .fetchStream<SearchChunk>('/guest-api/search/', {
          method: 'POST',
          body: JSON.stringify({ query: search }),
          signal: abortController.signal,
          onChunk: ({ chunk }) => {
            if (chunk.type === 'FILTERS') {
              return;
            }

            setResults((prevResults) => {
              const newResults = [...(prevResults ?? [])];

              for (const unit of chunk.section.units) {
                newResults.push({
                  ...unit,
                  section: chunk.section.type,
                  sectionDates: 'date' in chunk.section ? chunk.section.date : undefined,
                });
              }

              return newResults;
            });
          },
        }
  )
      .then(() => {
        // In case we haven't received any data, we need to set the results to an empty object
        // so that the empty state component is rendered
        setResults((results) => {
          if (results === undefined) {
            return [];
          }
          return results;
        });
      })
      .catch((e) => {
        if (e?.name === 'AbortError') {
          return;
        }

        setResults([]);

        console.error('Error while streaming search results', e);
      });

    return () => {
      try {
        abortController.abort();
      } catch {
        // Ignore
      }
    };
  }, [search]);

  const renderItem = useCallback((unit: AiSearchResult[number], index: number, array: AiSearchResult) => {
    const prevUnit = array[index - 1];
    const prevItemSectionName = !prevUnit
      ? undefined
      : prevUnit.sectionDates?.checkIn && prevUnit.sectionDates?.checkOut
        ? `${format(parseISO(prevUnit.sectionDates.checkIn), 'LLL d')} – ${format(parseISO(prevUnit.sectionDates.checkOut), 'LLL d')}`
        : sectionTypeToLabel[prevUnit.section] ?? prevUnit.section ?? undefined;

    const newItemSectionName =
      unit.sectionDates?.checkIn && unit.sectionDates?.checkOut
        ? `${format(parseISO(unit.sectionDates.checkIn), 'LLL d')} – ${format(parseISO(unit.sectionDates.checkOut), 'LLL d')}`
        : sectionTypeToLabel[unit.section] ?? unit.section ?? undefined;

    const isFirstOfSection = prevItemSectionName !== newItemSectionName;

    return (
      <Fragment key={unit.id + '_' + newItemSectionName}>
        {isFirstOfSection && (
          <h2 className={clx('col-span-full px-2 text-lg font-medium', index !== 0 && 'pt-6')}>{newItemSectionName}</h2>
        )}
        <Property className='p-2' property={unit} lazyLoad={index > 3} />
      </Fragment>
    );
  }, []);

  return isLoading ? <ListProperties_Fallback /> : isEmptyState ? <ErrorMessage /> : results.map(renderItem);
};

export default AiResults;
