'use client';

import React, { useRef } from 'react';

import NavBar, { type NavBarRef } from '@wander/ui/src/components/NavBar';

import { useSearchParams } from 'next/navigation';
import scrollToCategories from '../../../actions/scrollToCategories';
import { useSearch } from '../SearchProvider/SearchProvider_Context';

const useNavigateCategories = () => {
  const navBarRef = useRef<NavBarRef>(null);
  const searchParams = useSearchParams();
  const { search } = useSearch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const target = event.currentTarget;
    scrollToCategories();
    // @ts-ignore
    navBarRef.current?.moveTo(target);

    const params = new URLSearchParams(searchParams);
    const category = target.getAttribute('data-category');
    if (category) {
      if (params.has('search')) {
        params.delete('search');
      }
      params.set('category', category);
    } else {
      params.delete('category');
    }
    search(`?${params.toString()}`);
  };

  return [navBarRef, handleClick] as const;
};

const CategoriesNavBar = ({ children }: { children: React.ReactNode }) => {
  const [navBarRef, handleClick] = useNavigateCategories();

  const searchParams = useSearchParams();
  const { search } = useSearch();

  const currentCategory = searchParams.get('category')?.toString() || null;

  const [all, ...categories] = React.Children.toArray(children);

  if (!all || !categories) {
    throw new Error('CategoriesNavBar must have at least 2 children');
  }

  return (
    <NavBar innerRef={navBarRef}>
      {React.isValidElement(all) &&
        React.cloneElement(all, {
          // @ts-ignore
          'aria-selected': currentCategory === null,
          onClick: handleClick,
        })}
      {React.Children.map(
        categories,
        (child) =>
          React.isValidElement(child) &&
          React.cloneElement(child, {
            // @ts-ignore
            'aria-selected': currentCategory === child.props['data-category'],
            onClick: handleClick,
            onMouseOver: () => {
              const params = new URLSearchParams(searchParams);
              const category = child.props['data-category'];
              if (category) {
                params.set('category', category);
              } else {
                params.delete('category');
              }
              search(`?${params.toString()}`, true);
            },
          })
      )}
    </NavBar>
  );
};

export default CategoriesNavBar;
