const scrollToCategories = (force = false) => {
  if (window.scrollY > 800 || force) {
    window.scrollTo({
      top: (document.querySelector('#properties-list')?.getBoundingClientRect().top || 0) + window.scrollY - 110,
      behavior: 'smooth',
    });
  }
};

export default scrollToCategories;
