'use client';

import Link from 'next/link';

import Icon from '@wander/ui/src/components/Icon';

import useUser from '@/features/user/hooks/useUser';

const ReferralsDetails = () => (
  <>
    <Icon name='gift' className='h-7 w-7 self-center text-w-4004' />
    <div className='grid'>
      <span className='font-medium'>Give $250, get $250</span>
      <p className='text-sm text-w-4004'>toward your next Wander adventure</p>
    </div>
  </>
);

const Referrals = () => {
  const { user, toggleLoginDialog } = useUser();

  return user ? (
    <Link href='/profile/referrals' className='grid w-full grid-cols-[auto_1fr] gap-3 rounded-lg bg-w-90015 p-3'>
      <ReferralsDetails />
    </Link>
  ) : (
    <button
      className='grid w-full grid-cols-[auto_1fr] gap-3 rounded-lg bg-w-90015 p-3 text-left'
      onClick={() => toggleLoginDialog()}
    >
      <ReferralsDetails />
    </button>
  );
};

export default Referrals;
