'use client';

import { useEffect, useRef, useState } from 'react';

import onScreenObserver from '@/utils/onScreenObserver';
import PropertyFallback from '../Property/PropertyFallback';
import { useSearch } from './SearchProvider/SearchProvider_Context';

const LoadMore = ({
  fetchNextPage,
  isPending,
}: {
  fetchNextPage: () => void;
  isPending: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (!isPending && loadMore) {
      fetchNextPage();
    }
  }, [fetchNextPage, loadMore, isPending]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const observer = onScreenObserver();
    const unobserve = observer.onIntersection(ref.current, (isIntersecting) => {
      setLoadMore(isIntersecting);
    });
    return () => unobserve();
  }, []);

  return (
    <>
      <PropertyFallback innerRef={ref} className='animate-pulse px-2 py-3' />
      {[...Array(10).keys()].map((_, i) => (
        <PropertyFallback key={i} className='animate-pulse px-2 py-3' />
      ))}
    </>
  );
};

export default LoadMore;
